import { graphql, PageProps } from "gatsby"; // This part is important - it helps with instructing gatsby to inject the context passed in
import React, { useMemo } from "react";
import styled from "styled-components";
import { DetailLine } from "../components/General/DetailLine/DetailLine";
import Layout from "../components/Layout";
import { ContentWrapper } from "../components/Layout/style";
import { device, fontHeading, MainTheme } from "../components/Layout/theme";

interface NotFoundProps {
  allContentstack404: {
    nodes: Array<{
      title: string;
      cta: string;
      backgroundImage: {
        url: string;
      };
      backgroundImageMobile: {
        url: string;
      };
      logo: {
        url: string;
      };
    }>;
  };
}

const StyledSection = styled.section<{ background: string; mobileBackground: string }>`
  position: relative;
  overflow: hidden;
  height: calc(100vh - 80px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(${({ mobileBackground }): string => mobileBackground});

  @media ${device.minWidth1024} {
    background-image: url(${({ background }): string => background});
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media ${device.minWidth1024} {
    align-items: flex-start;
    text-align: left;
    margin-top: 10%;
  }
`;

const StyledLogo = styled.img`
  width: 100px;
  margin-bottom: 20px;

  @media ${device.minWidth768} {
    width: 200px;
    margin-bottom: 40px;
  }

  @media ${device.minWidth1024} {
    width: 160px;
    filter: brightness(0) invert(1);
  }
`;

const TitleWrapper = styled.div`
  position: relative;

  div {
    display: none;
  }

  @media ${device.minWidth1024} {
    width: 70%;

    div {
      display: block;
    }
  }
`;

const SectionTitle = styled.h2`
  ${fontHeading};
  margin-right: -10px;
  line-height: 0.95;
  letter-spacing: 2px;
  color: #141e37;
  font-size: 4rem;
  padding-left: 2.8rem;
  [dir="rtl"] & {
    padding: 10px 12px;
  }

  span {
    display: block;
  }

  @media ${device.minWidth1024} {
    font-size: 9rem;
    color: #fff;
  }
`;

const BackCTA = styled.a`
  ${fontHeading};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  height: 55px;
  padding: 0 30px;
  position: relative;
  margin-top: 60px;
  z-index: 1;
  letter-spacing: 1px;

  &::after {
    content: "";
    transition: ${MainTheme.colorTransition};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${MainTheme.colorElectricBlue};
    transform: skewX(-20deg);
    letter-spacing: 1px;
    z-index: -1;
  }

  &:hover {
    &::after {
      background-color: ${MainTheme.colorMidnightBlue};
      transition: ${MainTheme.colorTransition};
    }
  }

  &:focus {
    outline: none;
  }
`;

const NotFoundPage: React.FC<PageProps<NotFoundProps>> = ({
  data: {
    allContentstack404: {
      nodes: [{ title, logo, backgroundImage, backgroundImageMobile, cta }],
    },
  },
}) => {
  const titleLines = useMemo<Array<string>>(() => {
    const splitLines = title.split("-");
    return splitLines.length > 0 ? splitLines.map((line, index) => (index === 0 ? `${line}-` : line)) : splitLines;
  }, [title]);
  return (
    <Layout>
      <StyledSection background={backgroundImage.url} mobileBackground={backgroundImageMobile.url}>
        <ContentWrapper>
          <HeaderWrapper>
            <StyledLogo src={logo.url} data-testid="404:logo" />
            <TitleWrapper>
              <DetailLine />
              <SectionTitle data-testid="404:title">
                {titleLines.map((line, index) => (
                  <span key={index}>{line}</span>
                ))}
              </SectionTitle>
            </TitleWrapper>

            <BackCTA href="/" data-testid="404:button">
              {cta}
            </BackCTA>
          </HeaderWrapper>
        </ContentWrapper>
      </StyledSection>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query NotFoundQuery($language: String!) {
    allContentstack404(filter: { publish_details: { locale: { eq: $language } } }) {
      nodes {
        title
        cta
        backgroundImage: background_image_desktop {
          url
        }
        backgroundImageMobile: background_image_mobile {
          url
        }
        logo: logo_image {
          url
        }
      }
    }
  }
`;
